import React, { memo, useCallback, useEffect, useMemo } from "react";
import { ISubordinatesList } from "./SubordinatesList.interface";
import { AvatarGroup } from "../../../../../uiKit";
import { Subordinate } from "../subordinate/Subordinate";
import { api } from "../../../../../../services";
import { usePagingWithController } from "../../../../../../hooks/usePaging";


export const SubordinatesList = memo((props: ISubordinatesList) => {
  const isCheckedHiddenUser = useMemo(() => {
    // @ts-ignore
    const lastIndex: number = props.subordinatesList.findLastIndex((item) => props.filter.includes(item.id));
    return lastIndex >= (props.maxCount ?? 6);
  }, [props.subordinatesList, props.filter]);

  console.log("isCheckedHiddenUser", isCheckedHiddenUser);

  return props.subordinatesList.length > 0 ? (
      <AvatarGroup
        maxCount={props.maxCount ?? 6}
        // className={"subordinates-list"}
        prefixCls={"subordinates-list"}
        maxStyle={{
          border: isCheckedHiddenUser ? "1.5px solid var(--color-calendar-primary-base)" : "1px solid var(--color-layout-drawer)",
          width: 32,
          height: 32,
          boxSizing: "border-box",
        }}
      >
        {
          props.subordinatesList.map((user, i) => (
            <Subordinate
              key={user.id}
              user={user}
              index={i}
              isActive={props.filter.includes(user.id!)}
              onChange={props.onChangeFilter}
              isChoiceOnlyOneUser={props.isChoiceOnlyOneUser}
              maxCount={props.maxCount ?? 6}
            />
          ))
        }
      </AvatarGroup>
  ) : null
});