import { usePagingWithController } from "../../../../../../hooks/usePaging";
import { api } from "../../../../../../services";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDateHelpers, useLocalStorage, useRootStore } from "../../../../../../hooks";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { IssueRelationType } from "../../../../../pages/communication/misc/constsAndTypes";
import { CalendarPlanningSidebarView } from "./CommunicationSidebarCalendarPlanningView";
import { BroadcastChannel } from "broadcast-channel";
import dayjs from "dayjs";
import { useLocation, useSearchParams } from "react-router-dom";
import { checkQueryIssueId } from "../../../../../service/issueUpdateWraper/helpers";
import { BoardFilterContext } from "../../../../../../contexts/communication/boardFilterContext";

export interface ICalendarPlanningSidebarProps {
  isSidebarOpen: boolean;
  sidebarSize: { width: number };

  onSidebarOpenChange: () => void;
  onSidebarSizeChange: ({ width }: { width: number }) => void;
  onSidebarResizing: (isResizing: boolean) => void;
}

const CalendarPlanningSidebarObserved = (props: ICalendarPlanningSidebarProps) => {
  const { t } = useTranslation();
  const { boardStore, authStore, orgchartStore, communicationCalendarPlanningStore } = useRootStore();
  const { formatTimeSpan } = useDateHelpers();

  const { userId: userIds } = useContext(BoardFilterContext);

  const userId = useMemo(() => {
    const [user] = userIds;
    return user;
  }, [userIds]);

  const [plannedTime, setPlannedTime] = useState<string | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0)

  const [savedFilter, setSavedFilter] = useLocalStorage<{
    isOverdueDeadline: boolean;
    isNotStatusDone: boolean;
  }>("calendarPlanningSidebarFilter", {
    isOverdueDeadline: false,
    isNotStatusDone: false,
  });

  const [filter, setFilter] = useState<{
    isOverdueDeadline: boolean;
    isNotStatusDone: boolean;
  }>({
    isOverdueDeadline: false,
    isNotStatusDone: false,
  });

  useEffect(() => {
    setSavedFilter(filter);
  }, [filter]);

  useEffect(() => {
    setFilter(savedFilter);
  }, []);

  const setIsOverdueDeadline = useCallback((value) => {
    setFilter((prev) => ({
      ...prev,
      isOverdueDeadline: value,
      isNotStatusDone: false,
    }));
  }, []);
  const setIsNotStatusDone = useCallback((value) => {
    setFilter((prev) => ({
      ...prev,
      isNotStatusDone: value,
      isOverdueDeadline: false,
    }));
  }, []);

  // const [isOverdueDeadline, setIsOverdueDeadline] = useState<boolean>(false);
  // const [isNotStatusDone, setIsNotStatusDone] = useState<boolean>(false);

  const isActiveFilter = useMemo(() => {
    const { isOverdueDeadline, isNotStatusDone} = filter;
    return isOverdueDeadline || isNotStatusDone;
  }, [filter])


  const issueShort = usePagingWithController(
    api.issueShort,
    {
      // boardColumnId: boardStore.getBoard?.columns?.[0]?.id,
      boardId: boardStore.getBoardId,
      isActive: true,
      isArchived: false,
      userRelation: IssueRelationType.MY,
      // orgchartId: orgchartStore.getCurrentOrgchartId,
      isDateWorkStartSet: filter.isNotStatusDone || undefined,
      isUnplanned: isActiveFilter ? undefined : true,
      userId: userIds,
      dateDeadlineTo: filter.isOverdueDeadline
        ? dayjs().tz().toISOString()
        : undefined,
      dateWorkStartTo: filter.isNotStatusDone
        ? dayjs().add(-1, "day").endOf("day").tz().toISOString()
        : undefined,
      boardStatusKeyIsNot: isActiveFilter ? "done,approval" : undefined,
    },
    {
      pageSize: 30,
    }
  );


  const getPlannedTime = async () => {
    const r = await api.issue.getPlannedTime({
      // boardColumnId: boardStore.getBoard?.columns?.[0]?.id,
      boardId: boardStore.getBoardId,
      isActive: true,
      isArchived: false,
      userRelation: IssueRelationType.MY,
      // orgchartId: orgchartStore.getCurrentOrgchartId,
      isDateWorkStartSet: filter.isNotStatusDone || undefined,
      isUnplanned: isActiveFilter ? undefined : true,
      userId,
      dateDeadlineTo: filter.isOverdueDeadline
        ? dayjs().tz().toISOString()
        : undefined,
      dateWorkStartTo: filter.isNotStatusDone
        ? dayjs().add(-1, "day").endOf("day").tz().toISOString()
        : undefined,
      boardStatusKeyIsNot: isActiveFilter ? "done,approval" : undefined,
    });
    if (r == null) {
      return;
    }
    setPlannedTime(r.plannedTime ?? "00:00:00");
    setTotalItems(r.issueCount ?? 0);
  };

  const handleDataReload = async (state?: boolean | true) => {
    issueShort.reset();
    await issueShort.restart(state);
    await getPlannedTime();
  };

  const handleDragStart = (maxDate: Date) => {
    communicationCalendarPlanningStore.setMaxDropDate(maxDate);
  };

  const handleDragEnd = () => {
    communicationCalendarPlanningStore.setMaxDropDate(null);
  };

  useEffect(() => {
    handleDataReload();
  }, [boardStore.getBoard, filter, userId]);

  const totalTimePlan: string = useMemo(() => {
    if (plannedTime == null || plannedTime === "00:00:00") return `0${t("parse:date_helper.hour")}`;
    return formatTimeSpan(plannedTime);
  }, [plannedTime]);


  const handleRemoveItem = async (item: any) => {
    const toDay = dayjs().add(-1, "day").endOf("day").tz().toISOString();
    const issueDay = dayjs(item.dateWorkStart).tz().toISOString();
    if (dayjs(issueDay).isBefore(toDay) && filter.isNotStatusDone) {
      issueShort.reset();
      await issueShort.restart();
    } else {
      issueShort.removeItem(item, issueShort.items);
    }
    await getPlannedTime();
  };

  useEffect(() => {
    communicationCalendarPlanningStore.setOnSidebarReload(handleRemoveItem);
  }, [issueShort.items.length]);

  const issueChannel = useMemo(() => new BroadcastChannel("issue"), []);

  const isReload = useRef(false);

  const location = useLocation();

  const issueParamsId = checkQueryIssueId(location.search);
  // const [isForceReload, setIsForceReload] = useState<boolean>(false);

  const isForceReload = useRef(false);

  useEffect(() => {
    if (isForceReload.current) {
      handleDataReload();
      isForceReload.current = false;
    }
  }, [issueParamsId])

  const handleIssueChannelMessage = async (e: any) => {
    const { issueId, type } = e;
    if (isActiveFilter) {
      isForceReload.current = true;
      // handleDataReload();
    } else {
      if (type === "issueDelete") {
        await issueShort.removeItem({ id: issueId }, issueShort.items);
      } else {
        await issueShort.getById(issueId, issueShort.items, 'dateWorkStart');
      }
      await getPlannedTime();
    }
  }

  useEffect(() => {
    issueChannel?.addEventListener("message", handleIssueChannelMessage);

    return () => {
      issueChannel?.removeEventListener("message", handleIssueChannelMessage);
    };
  }, [issueChannel, issueShort.items.length]);

  return (
    <CalendarPlanningSidebarView
      {...props}
      isLoading={issueShort.info.isLoading}
      isDone={issueShort.info.isDone}
      totalItems={totalItems ?? 0}
      totalTimePlan={totalTimePlan}
      items={issueShort.items}
      isReload={isReload.current}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onLoadNext={issueShort.loadNext}
      isOverdueDeadline={filter.isOverdueDeadline}
      setIsOverdueDeadline={setIsOverdueDeadline}
      isNotStatusDone={filter.isNotStatusDone}
      setIsNotStatusDone={setIsNotStatusDone}
      isActiveFilter={isActiveFilter}
    />
  );
};

export const CalendarPlanningSidebar = observer(CalendarPlanningSidebarObserved);
