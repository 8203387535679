import { RelationsTypes } from "../components/boardRelationSwitch/BoardRelationSwitch.interface";
import { reqSortFiltersKeys, sortKeys, sortKeyType } from "../../../../pages/communication/misc/consts";
import { RelationsKeys } from "../constants/keys";

export interface IRegeneratorFilters {
  userRelation: RelationsTypes;
  sortValue?: sortKeyType;
  boardId?: number | null;
  senderId?: number[];
  hasViolation: boolean;
  executorId?: number[];
  flowType?: number;
  scheduledToday: boolean;
}

export const handleGetBoardStatusKeyByFlowType = (flowType?: number) => {
  switch (flowType) {
    case 0:
      return {
        boardStatusKey: undefined,
        boardStatusKeyIsNot: "approval",
      };
    case 1:
      return {
        boardStatusKey: "approval",
        boardStatusKeyIsNot: undefined,
      };
    default:
      return {
        boardStatusKey: undefined,
        boardStatusKeyIsNot: undefined,
      };
  }
};

export const regeneratorFilters = (filters: IRegeneratorFilters) => ({
  getReqFilters: (isHiddenFilter?: boolean) => {
    if (isHiddenFilter) {
      return {}
    }
    if (filters.userRelation === RelationsKeys.My) {
      return {
        hasViolation: filters.hasViolation,
        initiatorUserId: filters.senderId ?? null,
        // flowType: filters.flowType == 3 ? undefined : filters.flowType,
        boardStatusKey: handleGetBoardStatusKeyByFlowType(filters?.flowType)?.boardStatusKey,
        boardStatusKeyIsNot: handleGetBoardStatusKeyByFlowType(filters?.flowType)?.boardStatusKeyIsNot,
      };
    }
    if (filters.userRelation === RelationsKeys.Controlled) {
      return {
        hasViolation: filters.hasViolation,
        executorUserId: filters.executorId ?? null,
        // flowType: filters.flowType == 3 ? undefined : filters.flowType,
        boardStatusKey: handleGetBoardStatusKeyByFlowType(filters?.flowType)?.boardStatusKey,
        boardStatusKeyIsNot: handleGetBoardStatusKeyByFlowType(filters?.flowType)?.boardStatusKeyIsNot,
      };
    }
    if (filters.userRelation === RelationsKeys.Participated) {
      return {
        hasViolation: filters.hasViolation,
        executorUserId: filters.executorId ?? null,
        initiatorUserId: filters.senderId ?? null,
        // flowType: filters.flowType == 3 ? undefined : filters.flowType,
        boardStatusKey: handleGetBoardStatusKeyByFlowType(filters?.flowType)?.boardStatusKey,
        boardStatusKeyIsNot: handleGetBoardStatusKeyByFlowType(filters?.flowType)?.boardStatusKeyIsNot,
      };
    }
    return {};
  },
  getReqSortFilters: () => {
    switch (filters.sortValue) {
      case sortKeys.date:
        return {
          order: reqSortFiltersKeys.desc,
          orderBy: sortKeys.in_approval_first,
          thenOrder: reqSortFiltersKeys.desc,
          thenOrderBy: sortKeys.date,
        };
      case sortKeys.date_reverse:
        return {
          order: reqSortFiltersKeys.desc,
          orderBy: sortKeys.in_approval_first,
          thenOrder: reqSortFiltersKeys.asc,
          thenOrderBy: sortKeys.date,
        };
      case sortKeys.date_deadline:
        return {
          order: reqSortFiltersKeys.desc,
          orderBy: sortKeys.in_approval_first,
          thenOrder: reqSortFiltersKeys.asc,
          thenOrderBy: sortKeys.date_deadline,
        };
      case sortKeys.date_deadline_is_null:
        return {
          order: reqSortFiltersKeys.asc,
          orderBy: sortKeys.date_deadline_is_null,
        };
      case sortKeys.high_priority:
        return {
          order: reqSortFiltersKeys.desc,
          orderBy: reqSortFiltersKeys.hasPriority,
          thenOrder: reqSortFiltersKeys.desc,
          thenOrderBy: reqSortFiltersKeys.priority,
        };
      case sortKeys.low_priority:
        return {
          order: reqSortFiltersKeys.desc,
          orderBy: reqSortFiltersKeys.hasPriority,
          thenOrder: reqSortFiltersKeys.asc,
          thenOrderBy: reqSortFiltersKeys.priority,
        };
      default:
        return {
          order: reqSortFiltersKeys.asc,
          orderBy: sortKeys.date,
        };
    }
  },
});
