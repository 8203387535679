import React, { memo, useMemo } from "react";
import { ListIssues } from "../listIssues/ListIssues";
import { BoardColumnDto, BoardStatusDto } from "../../../../../../api";
import { IBoardColumnsState } from "../../../../../../contexts/communication/boardFilterContext";

export const Column = memo((
  {
    column,
    isOpenBoard,
    currentStatusFromBoard,
    onChangeBoardColumnsState,
    orgchartId,
    tagId,
    isGroupingByTags,
    userId,
    isGroupingByUsers,
  }: {
    orgchartId: number;
    tagId?: number;
    isGroupingByTags?: boolean;
    userId?: number;
    isGroupingByUsers?: boolean;
    isOpenBoard: boolean;
    onChangeBoardColumnsState: (newValue: IBoardColumnsState) => void;
    column: BoardColumnDto;
    currentStatusFromBoard?: BoardStatusDto;
  }
) => {
  // const statuses = context.board?.statuses?.filter(
  //   (s) =>
  //     s.key !== statusesForFiltering.reject &&
  //     s.key !== statusesForFiltering.approval &&
  //     s.key !== statusesForFiltering.not_approved
  // );

  const statusesForFiltering = {
    reject: "default.v1.status.rejected",
    approval: "default.v1.status.approval",
    not_approved: "default.v1.status.not_approved",
  };

  const status = useMemo(() => {
    const { statuses } = column;
    const statusesList = statuses!.filter(
      (s) =>
        s.key !== statusesForFiltering.reject &&
        s.key !== statusesForFiltering.approval &&
        s.key !== statusesForFiltering.not_approved
    );
    return statusesList[0];
  }, [column]);


  return (
    <div key={column.id} className="d-stack-column spacing-2">
      <ListIssues
        key={column.id}
        isOpen={isOpenBoard}
        onChangeBoardColumnsState={onChangeBoardColumnsState}
        orgchartId={orgchartId!}
        tagId={tagId}
        isGroupingByTags={isGroupingByTags}
        userId={userId}
        isGroupingByUsers={isGroupingByUsers}
        column={column}
        status={status}
        currentStatusFromBoard={currentStatusFromBoard}
      />
    </div>
  );
});