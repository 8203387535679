import React from "react";
import { IOrgchartCardView } from "./OrgchartCard.interface";
import { Avatar, Text, Tooltip } from "../../uiKit";

export const UserInfoViewMemo = (props: IOrgchartCardView) => {
  const OrgchartAvatar = (
    <Tooltip title={props.showTooltip ? props.orgchart?.name : undefined}>
      <Avatar
        gap={6}
        size={props.avatarSize ?? 20}
        src={props.orgchart?.image?.url}
        text={props.orgchart?.avatarText ?? props.orgchart?.name}
        color={props.orgchart?.colorHex}
      />
    </Tooltip>
  );

  if (props.isReversedCardItems) {
    return (
      <div className="user-info__container" style={{ maxWidth: "100%" }}>
        {!props.isAvatarOnly && (
          <Text
            className="user-info__name mr-2"
            weight={props.boldName ? 500 : 400}
            style={{ color: props.color }}
            size={props.fontSize != null ? `${props.fontSize}px` : undefined}
            children={props.orgchart?.name}
          />
        )}
        {OrgchartAvatar}
      </div>
    );
  }

  return (
    <div className="user-info__container" style={{ maxWidth: "100%" }}>
      {OrgchartAvatar}
      {!props.isAvatarOnly && (
        <Text
          className="user-info__name ml-2"
          weight={props.boldName ? 500 : 400}
          style={{ color: props.color }}
          size={props.fontSize != null ? `${props.fontSize}px` : undefined}
          children={props.orgchart?.name}
        />
      )}
    </div>
  );
};

export const OrgchartCardView = React.memo(UserInfoViewMemo, (x, y) => x?.orgchart?.id == y?.orgchart?.id);
