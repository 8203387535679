import React, { memo, useCallback, useMemo } from "react";
import { ISubordinate } from "./Subordinate.interface";
import { UserCard } from "../../../../../elements/userCard/UserCard";
import clsx from "clsx";
import "./Subordinate.scss";
import { Checkbox, Radio } from "../../../../../uiKit";

export const Subordinate = memo((props: ISubordinate) => {

  const isHidden = useMemo(() => {
    return props.index >= props.maxCount;
  }, [props.index, props.maxCount]);

  const handleChange = () => {
    props.onChange(props.user.id!)
  };


  const handleClick = () => {
    if (!isHidden) {
      handleChange()
    }
  };

  return (
    <div
      className={clsx("subordinate", {
        "subordinate_popover": isHidden,
        "subordinate_checked": !isHidden && props.isActive,
      })}
      onClick={handleClick}
    >
      <UserCard
        userInfo={props.user}
        avatarSize={isHidden ? 24 : 32}
        isNewStyle={true}
        hiddenUserInfo={!isHidden}
      />
      {isHidden && (
        <>
        { props.isChoiceOnlyOneUser ? (
          <Radio
            checked={props.isActive}
            onChange={handleChange}
            isNewDesign
          />
        ) : (
          <Checkbox
            checked={props.isActive}
            onChange={handleChange}
            isNewDesign
          />
          )
        }
        </>
      )}
    </div>
  )
});